.filtroContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 3rem;
  }
  
  .searchBox {
    position: relative;
  }
  
  .searchBox input {
    padding: 10px 40px 10px 15px;
    font-size: 16px;
    border-radius: 30px;
    border: 1px solid #021679;
    background-color: transparent;
    /* width: 300px; */
    width: 25rem;
    outline: none;
  }
  
  .searchIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #021679;
  }
  
  .dropdown {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .dropdown select {
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 30px;
    outline: none;
    appearance: none;
    background-color: #fff;
    cursor: pointer;
    padding-left: 40px;
    border: 1px solid #021679;
    background-color: transparent;
  }
  
  .dropdown .icon {
    position: absolute;
    left: 10px;
    font-size: 18px;
    color: #021679;
  }
  
  .dropdown select option {
    font-size: 16px;
    color: #333;
  }
  
  .botaoCadastrarVaga {
    background-color: #021679; 
    color: white; 
    padding: 10px 20px; 
    cursor: pointer; 
    font-size: 16px; 
    transition: background-color 0.3s ease; 
    display: flex; 
    align-items: center; 
    border-radius: 30px;
    border: 1px solid #ddd;
  }
  
  .botaoCadastrarVaga a {
    color: white; 
    text-decoration: none; 
    margin-left: 5px; 
  }
  
  .botaoCadastrarVaga:hover {
    background-color: #021679a1
  }
  
  .iconAdd {
    margin-right: 5px;
  }
  
  .noResults {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    color: #555;
  }

  @media (max-width: 768px) {
    .filtroContainer {
      display: flex; 
      flex-direction: column; 
      gap: 10px; 
      margin-top: 1.5rem; 
      padding: 0 15px; 
    }
  
    .searchAndButton {
      display: flex; 
      gap: 10px; 
      width: 100%; 
    }
  
    .searchBox input {
      flex: 1; 
      font-size: 14px; 
      padding: 10px; 
      border: 2px solid #ccc; 
      border-radius: 5px; 
      transition: border-color 0.3s; 
    }
  
    .searchBox input:focus {
      border-color: #007BFF; 
      outline: none; 
    }
  
    .botaoCadastrarVaga {
      background-color: #021679; 
      color: white; 
      padding: 10px; 
      border: none; 
      border-radius: 5px; 
      cursor: pointer; 
      transition: background-color 0.3s; 
    }
  
    .botaoCadastrarVaga:hover {
      background-color: rgba(2, 22, 121, 0.63) 87, 179, 0.589; 
    }
  
    .nivelSalarioContainer {
      display: flex; 
      gap: 10px; 
      width: 100%; 
      justify-content: space-between;
    }
  
    .nivelIcon, .salarioIcon {
      cursor: pointer;
      width: 40px; 
      height: 40px; 
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0; 
      border-radius: 5px; 
      transition: background-color 0.3s; 
    }
  
    .nivelIcon:hover, .salarioIcon:hover {
      background-color: #e0e0e0;
    }
  
    .dropdown {
      display: none; 
      width: 100%; 
      margin-top: 10px; 
      font-size: 14px; 
      padding: 10px; 
      border: 2px solid #ccc; 
      border-radius: 5px; 
      background-color: #fff; 
    }
  
    .noResults {
      font-size: 16px; 
      text-align: center; 
      color: #ff0000; 
    }
  
    .iconAdd {
      margin-right: 3px;
    }
  }
  
  