/* body {
    margin: 0 auto;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: black;
    color: white;
    max-width: 1800px;
} */

/* html{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden; 
    font-family: 'Montserrat', sans-serif;
    background: url('../assets/fundo.jpeg') no-repeat center center fixed;
    background-size: cover;
    color: white;
} */

body {

    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden; 
    font-family: 'Montserrat', sans-serif;
    background: url('../assets/fundo.jpeg') no-repeat center center fixed;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

main {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    flex-grow: 1;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* background-color: black; */
    padding: 2vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: padding 0.5s;
    /* background-color: rgba(0, 0, 0); */
}

.hidden {
    display: none;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    /* background-color: rgba(160, 68, 255, 0.8); */
    background: url('../assets/fundo5.jpg') no-repeat center center fixed;
    /* background-size: cover; */
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1001;
    display: flex;
    flex-direction: column;
}

.menu.open {
    transform: translateX(0);
    z-index: 1003;
    box-shadow: 3.5vh 3.5vh 3.5vh 0 rgb(0, 0, 0);
}

.menu-container {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.close-button {
    align-self: flex-end;
    padding: 5px 10px;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: bold;
}

.menu-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-container li {
    border-bottom: 3px solid #000000;
    padding: 0.1rem 0;
}

.menu-container li a {
    display: block;
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 0.0001rem 0.1rem;
}

.menu-container li a:hover {
    background-color: rgba(42, 2, 80, 0.486);
}

.menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1001;
    position: absolute;
    left: 2vw;
}

.menu-icon.black div {
    background: black;
}

.menu-icon div {
    width: 30px;
    height: 4px;
    background: white;
    transition: all 0.3s ease;
    position: relative;
}

.menu.open .menu-icon div:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.menu.open .menu-icon div:nth-child(2) {
    opacity: 0;
}

.menu.open .menu-icon div:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

.divHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10vh;
}

nav ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 3vw;
    padding-right: 8vw;
}

.Logo {
    width: 25rem;
    transition: width 0.5s ease; 
    padding: 2vh 1.5vw; 
    margin: 0 auto; 
    position: absolute;
    left: 50%; 
    transform: translateX(-50%); 
    z-index: 1000;
}

.logoHeader {
    width: 18rem;
    transition: width 0.5s ease;
}

.fixed-logo .logoHeader {
    width: 10rem; 
    transition: width 0.5s ease;
    opacity: 50%;
}

.fixed-logo .menu-icon {
    opacity: 50%;
}

.fixed-logo {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 2vh 0;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
 
.fixed-logo nav {
    display: none;
}

.topicoMenu a {
    text-decoration: none;
    color: white;
    font-size: 3vh;
    transition: color 0.3s;
}

.topicoMenu a:hover {
    font-size: 3.2vh;
    transform: scale(1.1);
    transition: font-size 0.3s;
}

.intro-text {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding-top: 22rem;
    font-size: 3.6rem;
    line-height: 1.5;
}

.highlighted {
    color: rgb(175, 166, 166);
    text-decoration: underline;
    opacity: 0;
    animation: fadeIn 2s ease forwards 1s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.veja-mais-button {
    border: 2px solid white;
    color: white;
    background-color: transparent;
    padding: 1.5rem 8rem;
    margin-top: 2rem;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s ease; 
    border-radius: 12px;
    position: relative;
    overflow: hidden;
}

.veja-mais-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #333131 0%, #222121 100%);
    transition: left 1.3s ease; 
}

.veja-mais-button:hover::before {
    left: 0;
}

.veja-mais-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; 
    opacity: 0;
    transition: opacity 0.3s ease;
}

.veja-mais-button:hover .veja-mais-text {
    opacity: 1; 
}

.whatsapp-logo {
    position: fixed;
    left: 2vw;
    bottom: 2vh;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0s linear 0.3s;
    z-index: 1002;
}

.whatsapp-logo img {
    width: 3vw;
    height: auto;
}

html {
    scroll-behavior: smooth;
}

.scroll-to-top {
    position: fixed;
    bottom: 2vh;
    right: 2vw;
    cursor: pointer;
    z-index: 1000;
    transition: opacity 0.3s;
}

.scroll-to-top:hover {
    opacity: 0.7;
}

.seta-icon {
    width: 4vh;
    height: 4vh;
}

footer {
    /* background-color: black; */
    /* background-color: rgba(0, 0, 0, 0.4); */
    color: white;
    padding: 2vh;
    text-align: center;
}



@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');

.hero {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    padding-top: 0.001rem;
    /* background: url('../assets/fundo.jpeg') no-repeat center center fixed; */
  }
  
  h1 {
    font-size: 64px;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  
  .highlight {
    font-weight: 700;
    color: #a044ff;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .word {
    display: inline-block;
    opacity: 0;
    animation: wordAnimation 2.8s forwards;
    animation-delay: calc(var(--word-index) * 0.5s);
    margin-bottom: 0.875rem;
  }
  
  .gradient-text {
    background: linear-gradient(to right, #a044ff, #6a3093);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  @keyframes wordAnimation {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .highlight:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8px;
    background: #a959fa;
    z-index: -1;
  }
  
  .subtitle {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .subtitle-line {
    margin-right: 8px;
  }
  
  .subtitle-highlight {
    background: linear-gradient(to right, #a044ff, #6a3093);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }
  
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    text-decoration: none;
    padding: 16px 36px;
    border-radius: 50px;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
  }
  
  .btn span {
    position: relative;
    z-index: 1;
  }
  
  
  .btn-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #a044ff, #6a3093);
    border-radius: 50px;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
  }
  
  .btn:hover {
    color: #000;
  }
  
  .btn:hover .btn-effect {
    transform: translateX(0);
  }

  /* .carrossel-component{
    margin-top: -15rem;
 } */
 
.vagas-background {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {

    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        overflow-x: hidden; 
        font-family: 'Montserrat', sans-serif;
        background: url('../assets/fundo.jpeg') no-repeat center center fixed;
        background-size: cover;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    header {
        padding-bottom: 0.1rem;
    }

    .hero {
        margin-top: 3rem;
    }

    nav ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0;
        margin-top: 1rem;
        margin-bottom: 0;
    }

    .fixed-logo nav {
        display: none;
    }

    .intro-text {
        text-align: center;
        margin-top: 0.1rem;
        padding-top: 10rem;
    }

    .intro-text p {
        font-size: 1.5rem;
        padding-top: 0.05rem;
        word-wrap: break-word;
        width: 80%;
        margin: 0 auto;
    }

    .logoHeader {
        width: 8rem;
        transition: width 1.5s ease;
        margin: 0 auto;
    }

    .fixed-logo .logoHeader {
        width: 5rem; 
        transition: width 1.5s ease;
        opacity: 50%;
    }

    .topicoMenu a {
        font-size: 1.2rem;
        margin-right: 0.875rem;
    }

    .whatsapp-logo {
        left: 6vw;
    }

    .seta-icon {
        width: 4.5vh;
        height: 3vh;
        padding-right: 4vw;
    }

    .fixed-logo .Logo {
        width: 25vw;
        transition: width 1.5s ease;
    }

    .whatsapp-logo img {
        width: 7vw;
        height: auto;
    }

    .veja-mais-button {
        border: 2px solid white;
        color: white;
        background-color: transparent;
        padding: 0.9rem 3.2rem;
        margin-top: 2rem;
        font-size: 1rem;
        cursor: pointer;
        transition: color 0.3s ease;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
    }
      
      h1 {
        font-size: 1.5rem;
        font-weight: 900;
        line-height: 1.2;
        margin-bottom: 1.2rem;
        margin-top: -10rem;
      }
            
      .subtitle {
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
              
    .subtitle-line {
        margin-right: 8px;
    }
            
      .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        text-decoration: none;
        padding: 12px 32px;
        border-radius: 50px;
        font-weight: 700;
        position: relative;
        overflow: hidden;
        transition: color 0.3s ease;
        font-size: 0.875rem;
      }

      .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(15, 15, 15);
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1001;
        display: flex;
        flex-direction: column;
    }

    .menu.open {
        box-shadow: 3.5vh 3.5vh 3.5vh 0 rgb(0, 0, 0);
    }

    .menu-container li {
        border-bottom: 3px solid black;
        padding: 0; 
        margin: 0; 
        width: 100%; 
    }
    
    .menu-container li a {
        display: block;
        color: white;
        text-decoration: none;
        font-size: 1.5rem;
        padding: 1rem 0;
        padding-left: 10px; 
        width: 100%; 
        box-sizing: border-box; 
        text-align: center;
    }

    .menu-container li a:hover {
        background-color: rgba(42, 2, 80, 0.486);
    }

    .menu-container {
        flex: 1;
        padding: 20px;
        box-sizing: border-box;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .close-button {
        align-self: flex-end;
        padding: 1.5rem 1.5rem;
        background-color: transparent;
        color: white;
        border: none;
        cursor: pointer;
        font-size: 1.8rem;
        margin-bottom: 20px;
        font-weight: bold;
    }
      
    .footer {
        padding-top: 7rem;
    }
}