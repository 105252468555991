.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem; 
  max-width: 75rem;
  margin: 0 auto;
  padding: 5rem 1.25rem; 
  margin-top: -5rem; 
}

.service {
  max-width: 50rem; 
  display: flex;
  align-items: center;
  gap: 2.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 3.75rem; 
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1); 
  transition: transform 2s ease-out, opacity 2s ease-out;
  opacity: 0; 
  transform: translateX(50px); 
}

.show {
  opacity: 1;
  transform: translateX(0);
}

.hide {
  opacity: 0;
  transform: translateX(50px); 
}

.serviceImage1,
.serviceImage2,
.serviceImage3 {
  flex-shrink: 0;
  width: 12.5rem; 
  height: 12.5rem; 
  border-radius: 22%;
  overflow: hidden;
  box-shadow: -1vh 1vh 1.5vh rgba(160, 68, 255, 0.8);
}

.serviceImage1 img,
.serviceImage2 img,
.serviceImage3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.serviceContent {
  flex-grow: 1;
  text-align: left;
}

.serviceTitle {
  font-size: 2rem;
  font-weight: 700;
  color: #a044ff;
  margin-bottom: 1.25rem; 
}

@media (max-width: 768px) {
  .service {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem; 
    gap: 1.5rem; 
    margin-top: 4rem;
    flex-direction: column;
    align-items: center;
  }

  .serviceImage1,
  .serviceImage2,
  .serviceImage3 {
    width: 14rem; 
    height: 10rem; 
    margin-bottom: 1.5rem;
    order: 2;
    box-shadow: 1vh 1vh 1.5vh rgba(160, 68, 255, 0.8);
    border-radius: 20px;
  }

  .serviceTitle {
    font-size: 1.5rem; 
    margin-bottom: 0.5rem;
    order: 1;
  }

  .services {
    padding: 2.5rem 1rem; 
  }

  .serviceContent {
    text-align: center; 
  }
}