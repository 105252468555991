.footer {
  /* background-color: #000000; */
  /* background-color: rgba(0, 0, 0, 0.8); */
  color: #fff;
  padding: 2vh;
  text-align: center;
  bottom: 0;
  width: 100%;
  padding-top: 0.5rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-section {
  flex: 1;
  text-align: center;
}

.linkedin,
.email {
  width: 4rem;
}

.logo {
  width: 12rem;
}

.copyright {
  flex: 1;
  text-align: center;
}

@media (max-width: 768px) {
  .logo {
    width: 30vw;
  }

.linkedin,
.email {
  width: 1.5rem;
}
}