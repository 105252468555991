.servicos {
    padding: 5vh 2vw;
    text-align: center;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
    gap: 2vh;
}

.servico {
    background-color: transparent;
    padding: 2vh;
    border-radius: 4vh;
    box-shadow: 0 0 2vh rgba(0, 0, 0, 0.1);
}

.servico h3 {
    margin-bottom: 1vh;
    font-size: 4vh;
    padding-top: 3.5vh;
}

.servico p {
    margin: 0;
    font-size: 2.5vh;
    padding-top: 0.1rem;
}

.textServicos {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding-top: 12rem;
    padding-bottom: 5rem;
    font-size: 4rem;
    color: #a044ff;        
}

.imageSite {
    width: 55%;
    border-radius: 3vh;
    box-shadow: 1vh 1vh 1.5vh rgba(160, 68, 255, 0.8); 
    transition: transform 0.3s ease-in-out; 
}

.imageSite:hover {
    transform: scale(1.1); 
}


.todos-servicos-button {
    border: 2px solid white;
    color: white;
    background-color: transparent;
    padding: 1.5rem 8rem;
    margin-top: 2rem;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s ease; 
    border-radius: 12px;
    position: relative;
    overflow: hidden;
}

.todos-servicos-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #333131 0%, #222121 100%);
    transition: left 1.3s ease; 
}

.todos-servicos-button:hover::before {
    left: 0;
}

.todos-servicos-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; 
    opacity: 0;
    transition: opacity 0.3s ease;
}

.todos-servicos-button:hover .todos-servicos-text {
    opacity: 1; 
}

@media (max-width: 768px) {
    .servico {
        width: 100%; 
    }

    .imageSite {
        width: 70%;
        border-radius: 3vh; 
        box-shadow: 1vh 1vh 1.5vh rgba(160, 68, 255, 0.8); 
        transition: none; 
        margin: auto; 
    }
    
    .grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    
    .servico {
        margin-bottom: 20px;
    }

    .textServicos {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        padding-top: 10rem;
        padding-bottom: 2.5rem;
        font-size: 3.5rem;
    }

    .todos-servicos-button {
        border: 2px solid white;
        color: white;
        background-color: transparent;
        padding: 0.9rem 3.2rem;
        margin-top: 2rem;
        font-size: 1rem;
        cursor: pointer;
        transition: color 0.3s ease;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
    }
}