.vagasContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.vagaItem {
  padding: 20px;
  border: 1px solid #021679;
  border-radius: 10px;
  background-color: transparent;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  min-height: 300px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vagaItem:hover {
  transform: scale(1.05);
}

.vagaItem h2 {
  font-size: 1.5rem;
  text-align: left;
  color: black;
}

.vagaItem .empresa {
  font-size: 1rem;
  color: gray;
  text-align: left; 
}

.infoItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  color: gray; 
}

.icon {
  font-size: 1.2rem;
  color: gray; 
}

.verBtn {
  margin-top: 20px;
  width: 8rem;
  height: 2rem;
  font-size: 15px;
  background-color: #021679;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.verBtn:hover {
  background-color: #021679a1;
}

body, html {
  margin: 0;
  height: 100%;
  /* background-color: #c9bebe25; */
}

.vagas-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1; 
  /* padding: 20px;
  background-color: #c9bebe25; */
}

footerVagas {
  /* background-color: #021679;  */
  padding: 20px; 
  text-align: center; 
  height: 100%;
}


.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: center; 
  height: 12rem; 
}

.loadingText {
  font-size: 1.5rem; 
  color: #021679;  
  margin-bottom: 20px;  
  animation: fadeIn 1s ease-in-out;  
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinner {
  border: 8px solid #f3f3f3;  
  border-top: 8px solid #021679; 
  border-radius: 50%; 
  width: 50px;  
  height: 50px;  
  animation: spin 1s linear infinite;  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.vagas p {
  color: rgb(141, 22, 22);
}

.vagas-container-main {
  background-color: #fff;
}

@media (max-width: 768px) {
  .vagasContainer {
    display: grid;
    grid-template-columns: 1fr; 
    justify-items: center; 
    gap: 15px;
    margin-top: 15px;
  }

  .vagaItem {
    width: 70%; 
    padding: 15px;
    min-height: 250px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .vagaItem h2 {
    font-size: 1.2rem;
    color: black;
  }

  .vagaItem .empresa {
    font-size: 0.9rem;
  }

  .verBtn {
    width: 7rem;
    height: 1.8rem;
    font-size: 14px;
  }
}

