.headerVagas {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    background-color: #021679; 
    padding: 10px;
    border-bottom: 2px solid #ddd;
    margin-bottom: 7rem;
  }
  
  .headerVagas-left,
  .headerVagas-right {
    text-align: center;
  }
  
  .headerVagas-center {
    display: flex;
    justify-content: center;
  }
  
  .headerVagas-logo {
    width: 100px; 
  }
  
  .headerVagas-left p,
  .headerVagas-right p {
    font-size: 1.2rem;
    color: #333; 
  }

  .headerVagas-center img {
    width: 200px; 
    height: auto;
  }
  
  @media (max-width: 768px) {
    .headerVagas {
      display: flex;
      flex-direction: column; 
      align-items: center;
      padding: 10px;
      border-bottom: 2px solid #ddd;
    }
  
    .headerVagas-left,
    .headerVagas-right {
      display: none; 
    }
  
    .headerVagas-center {
      justify-content: center;
      width: 100%; 
      text-align: center;
    }
  
    .headerVagas-logo {
      width: 80px; 
    }
  
    .headerVagas-center img {
      width: 150px;
      height: auto;
    }
  }
  