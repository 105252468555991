.footerVagas {
  background-color: #42424200;
  background-color: #021679;
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 30px;
}

.footer-logoVagas {
  width: 100px; 
  height: auto;
  margin-bottom: 10px;
}

.footerVagas p {
  margin: 5px 0;
  font-size: 1rem;
  color: #ffffff;
}

.footerVagas a {
  color: #ffffff; 
  text-decoration: none;
  font-weight: bold;
}

.footerVagas a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .footerVagas {
    padding: 15px;
  }

  .footer-logoVagas {
    width: 80px; 
    margin-bottom: 8px;
  }

  .footerVagas p {
    font-size: 0.9rem; 
  }

  .footerVagas a {
    font-size: 0.9rem; 
  }
}