.depoimentos-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
}

.textDepoimentos {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  padding-top: 12rem;
  padding-bottom: 5rem;
  font-size: 4rem;
  color: #a044ff;        
}

.depoimento {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  flex: 1;
  min-width: 200px; 
  max-width: 60%; 
}

.depoimento-logo-container {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 15px; 
  overflow: hidden; 
}

.depoimento-logo {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}

.depoimento-company {
  font-size: 2rem;
  color: var(--gray-100);
  margin-bottom: 10px;
}

.depoimento-feedback {
  font-size: 1.2rem;
  color: rgb(160 68 255 / 80%); 
}


@media (max-width: 768px) {

  .textDepoimentos {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      text-align: center;
      padding-top: 10rem;
      padding-bottom: 2.5rem;
      font-size: 3.5rem;
  }

  .depoimentos-container {
    flex-direction: column;
    align-items: center;
  }

  .depoimento {
    max-width: 100%; 
  }

  .depoimento-logo-container {
    width: 150px;
    height: 150px;
  }

  .depoimento-company {
    font-size: 1.5rem; 
  }

  .depoimento-feedback {
    font-size: 1rem; 
  }
}
