.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 021679 */

.modalContent {
  background-color: #f5f1f1;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  display: flex;
  flex-direction: column;
  text-align: left; 
}

.modalContent h2 {
  margin-bottom: 15px;
  color: #000000;
}

.modalContent p {
  margin: 10px 0;
  color: #000000;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btnCandidatar, .btnFechar, .btnEnviarWhatsApp {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btnCandidatar {
  background-color: #000000;
  color: #fff;
}

.btnCandidatar:hover {
  background-color: #00000042;
}

.btnFechar {
  background-color: #c82333;
  color: #fff;
}

.btnFechar:hover {
  background-color: #dc3545;
}

.formCandidatura {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.formCandidatura input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btnEnviarWhatsApp {
  background-color: #25d366;
  color: white;
}

.btnEnviarWhatsApp:hover {
  background-color: #128c7e;
}

.whatsCancelContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.btnCancelar {
  background-color: #c82333;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 0 0 20%;
}

.btnCancelar:hover {
  background-color: #dc3545; 
}

.btnEnviarWhatsApp {
  background-color: #25d366;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 0 0 78%;
}

.btnEnviarWhatsApp:hover {
  background-color: #128c7e;
}

@media (max-width: 768px) {
  .modalContent {
    width: 90%; 
    padding: 15px;
  }

  .modalContent h2 {
    font-size: 1.5rem;
  }

  .modalContent p {
    font-size: 1rem; 
  }

  .modalFooter {
    flex-direction: column;
    gap: 10px;
  }

  .btnCandidatar,
  .btnFechar,
  .btnEnviarWhatsApp {
    padding: 8px 15px; 
    font-size: 0.9rem; 
  }

  .formCandidatura input {
    padding: 8px;
    font-size: 0.9rem; 
  }
}
