:root {
    --white: #fff;
    --gray-100: #e1e1e6;
    --gray-300: #c4c4cc;
    --gray-400: #8d8d99;
    --gray-600: #323238;
    --gray-700: #29292e;
    --gray-800: #202024;
    --gray-900: #121214;

    --green-300: #00b37e;
    --green-500: #00875f;

    --red-500: #f75a68;

    --blue-400: #3F4ED8;

    --purple-500: #a044ff;
    --purple-400: rgba(160, 68, 255, 0.8);
}

/* :focus {
    outline: transparent;
    box-shadow: 0 0 0 2px var(--purple-400);
} */

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */