.carrossel {
    overflow: hidden;
    width: 100%;
    position: relative;
    background:rgba(160, 68, 255, 0.3);
    background: url('../assets/fundo5.jpg') no-repeat center center fixed;
    background-size: cover;
    padding: 10px 0;
    margin-top: 0;
}

.carrosselTrack {
    display: flex;
    width: calc(5rem * 32);
    animation: scroll 30s linear infinite;
}

.carrosselItem {
    flex: 0 0 auto;
    width: 5rem; 
    height: 5rem; 
    margin: 0 10px;
    /* border: 2px solid var(--purple-500);  */
    /* border-radius: 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.carrosselItem img {
    width: 80%; 
    height: auto;
    display: block;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}


@media (max-width: 768px) {

    .carrossel {
        overflow: hidden;
        width: 100%;
        position: relative;
        /* background:rgba(160, 68, 255, 0.3); */
        padding: 6px 0;
        margin-top: 0;
        background: url('../assets/fundo5.jpg') no-repeat center center fixed;
        background-size: cover;
    }

    .carrosselItem {
        flex: 0 0 auto;
        width: 5rem; 
        height: 5rem; 
        margin: 0 10px;
        /* border: 2px solid var(--purple-500);  */
        /* border-radius: 15px; */
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .carrosselItem img {
        width: 60%; 
        height: auto;
        display: block;
    }
}